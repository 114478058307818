.qr-scanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scanner-wrapper {
    position: relative;
    width: 100%;
    max-width: 500px;
    aspect-ratio: 1; /* 1:1 aspect ratio */
  }
  
  .scanner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scanner-border {
    width: 80%;
    height: 80%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    position: relative;
  }

  .scanner-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    /* transform: translateY(-50%); */
    animation: scan 5s infinite
  }

  @keyframes scan {
    0% {
      top: 0;
    }
    50% {
      top: 100%;
    }
    100% {
      top: 0;
    }
  }
  
  .corner {
    position: absolute;
    width: 25px;
    height: 25px;
    border: 5px solid #fff;
  }
  
  .top-left {
    top: -5px;
    left: -5px;
    border-top-left-radius: 5px;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  
  .top-right {
    top: -5px;
    right: -5px;
    border-top-right-radius: 5px;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  
  .bottom-left {
    bottom: -5px;
    left: -5px;
    border-bottom-left-radius: 5px;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  
  .bottom-right {
    bottom: -5px;
    right: -5px;
    border-bottom-right-radius: 5px;
    border-left-color: transparent;
    border-top-color: transparent;
  }
  